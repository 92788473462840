import Adlat_logo2 from '../../public/images/Adlat_logo .png';
import '../../styles/Footers.css';
const Footers = () => {
    return ( <>
    
    <footer class="">
    <div class="footer container px-4 py-4 mx-auto">
        <div class="lg:flex">
            <div class="w-full -mx-6 lg:w-2/5">
                <div class="px-6">
                    <div>
                        <p href="#" class="text-xl font-bold text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300"><img src={Adlat_logo2} height="100"  width={100}/></p>
                    </div>
                    
                    <p class="max-w-md mt-2 text-gray-500 dark:text-gray-400">21 Owodunni Street, Iwaya, Yaba</p>
                    
                
                </div>
            </div>

            <div class="mt-6 lg:mt-0 lg:flex-1">
                <div class=" footer-col grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                    <div>
                        <h3 class="text-gray-700 uppercase dark:text-white">Contact Info</h3>
                        <p href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Tel: +234 (0) 802 346 7582</p>
                        <p href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Email: food@adlatcatering.com</p>
                        
                    </div>

                    <div>
                        <h3 class="text-gray-700 uppercase dark:text-white">Open Hours</h3>
                        <p href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Mondays to Fridays - 8am till 6pm</p>
                        <p href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Saturdays - 6am till 7pm</p>
                        <p href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Sundays - 10am till 4pm</p>
                    </div>

                    <div>
                        <h3 class="text-gray-700 uppercase dark:text-white">Legals</h3>
                        <a href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Terms of Service</a>
                        <a href="#" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Privacy Policy</a>
                        
                    </div>

                    <div className='copyright'>
                        <h3 class="text-gray-700 uppercase dark:text-white"></h3>
                        <span class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">©2022 Adlat Choice Nigeria Enterprises . All Rights Reserved</span>
                        <span class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"><a href="https://www.babskenky.com/bkhome">Designed and developed with love❤ by <span style={{color:'#E5A950'}}>Daniel.</span></a></span>
                    </div>
                </div>
            </div>
        </div>

        

        
    </div>
</footer>
    </> 
    );
}
 
export default Footers;